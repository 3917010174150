.reactJewishDatePicker {
    --color: #333;
    --backgroundColor: #e6e6e6;
    position: relative;
    display: grid;
    font-family: arial;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .reactJewishDatePicker .selectedDate {
    padding: 2px 15px 2px 5px;
    border: 1px solid var(--color);
    border-radius: 4px;
    user-select: none;
    min-height: 20px;
    text-align: center;
    position: relative;
    font-size: smaller;
  }
  .reactJewishDatePicker .calendarIcon {
    font-size: 16px;
    position: absolute;
    left: 5px;
    top: 4px;
  }
  .reactJewishDatePicker .monthWrapper {
    background-color: #fff;
    border: 1px solid #d6d9dd;
    border-radius: 5px;
    position: absolute;
    top: 32px;
    z-index: 1;
    display: grid;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.26s ease;
    transition: max-height 0.5s ease-out;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    transition: all 0.4s ease-in-out;
  }
  .reactJewishDatePicker .monthWrapper.open {
    transform: scaleY(1);
  }
  .reactJewishDatePicker.isHebrew {
    direction: rtl;
  }
  .reactJewishDatePicker.isHebrew .startDay {
    background: pishcalitems;
    border-radius: 0 7px 7px 0;
    color: #fff;
    margin: 1px 0;
  }
  .reactJewishDatePicker.isHebrew .endDay {
    background:#173272;;
    border-radius: 7px 0 0 7px;
    color: #fff;
    margin: 1px 0;
  }
  .reactJewishDatePicker.isHebrew .weekday,
  .reactJewishDatePicker.isHebrew .day {
    font-size: 16px;
    padding: 0;
  }
  .reactJewishDatePicker .navigation {
    display: grid;
    grid-template-columns: 22px 138px 22px;
    justify-content: space-between;
    height: 22px;
    padding: 5px;
  }
  .reactJewishDatePicker .navigation .arrowRight,
  .reactJewishDatePicker .navigation .arrowLeft {
    display: grid;
    align-items: center;
    justify-content: center;
  }
  .reactJewishDatePicker .navigation .arrowRight span,
  .reactJewishDatePicker .navigation .arrowLeft span {
    border: solid black;
    border-width: 0 2px 2px 0;
    padding: 1px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    cursor: pointer;
    width: 6px;
    height: 6px;
  }
  .reactJewishDatePicker .navigation .arrowRight:hover,
  .reactJewishDatePicker .navigation .arrowLeft:hover {
    background-color: var(--backgroundColor);
  }
  .reactJewishDatePicker .navigation .arrowLeft span {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  .reactJewishDatePicker .navigation .monthYearSelection {
    display: grid;
    grid-template-columns: auto auto;
  }
  .reactJewishDatePicker .navigation .monthYearSelection select {
    border: 0px;
    outline: 0px;
  }
  .reactJewishDatePicker .navigation .monthYearSelection select:hover {
    cursor: pointer;
    background-color: var(--backgroundColor);
  }
  .reactJewishDatePicker .navigation .monthYearSelection option {
    background-color: #fff;
  }
  .reactJewishDatePicker.isHebrew .navigation .arrowLeft span {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  .reactJewishDatePicker.isHebrew .navigation .arrowRight span {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  .reactJewishDatePicker .month {
    color: var(--color);
    grid-template-columns: repeat(7, 1fr);
    z-index: 2;
    background-color: white;
    display: grid;
    width: 250px;
    height: 180px;
    border-radius: 5px;
    padding: 2px;
    text-align: center;
  }
  .reactJewishDatePicker .weekdayWrapper {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    background-color: #001476;
    padding: 2px;
  }
  .reactJewishDatePicker .weekday,
  .reactJewishDatePicker .day {
    display: grid;
    margin: 1px;
    padding: 1px 1px 0 0;
    align-items: center;
    justify-content: center;
    user-select: none;
    border-radius: 7px;
    cursor: pointer;
    font-size: 14px;
    color:  #173272 ;
  }
  .reactJewishDatePicker .day:hover {
    background-color: var(--backgroundColor);
  }
  .reactJewishDatePicker .weekday {
    color: #fff;
    font-weight: bold;
  }
  .reactJewishDatePicker .otherMonth {
    background: #fff;
    color: #777;
  }
  .reactJewishDatePicker .selectedDay {
    background:  #FFD700 ;
    color: #fff;
  }
  .reactJewishDatePicker .startDay {
    background:#173272;;
    border-radius: 7px 0 0 7px;
    color: #fff;
    margin: 1px 0;
  }
  .reactJewishDatePicker .isInRange {
    background-color:  #173272;
    color: #fff;
    border-radius: 0;
    margin: 1px 0;
  }
  .reactJewishDatePicker .endDay {
    background: #173272;
    border-radius: 0 7px 7px 0;
    color: #fff;
    margin: 1px 0;
  }
  .reactJewishDatePicker .startDay:hover,
  .reactJewishDatePicker .isInRange:hover,
  .reactJewishDatePicker .endDay:hover {
    background:   #173272 ;
  }
  .reactJewishDatePicker .noSelect {
    cursor: not-allowed !important;
    background-color: #f3f3f3;
  }
  .reactJewishDatePicker .noSelect:hover {
    background-color: #f3f3f3;
  }
  .reactJewishDatePicker .noSelect:active {
    pointer-events: none;
  }
  .reactJewishDatePicker .noSelect{
  color:  #173272  !important;
  background-color:  #FFD700 !important;
}
.disabled-date {
    background-color: red;
  }
  
  .approved-date {
    
    
  background-color: lightgreen;
  }
  
  /* #FFD700 הצבע הצהוב כתום
  #0D1E46 כחול
  rgb(255 107 129) ורוד
 
  ירוק rgb(212 225 108) */
  
  
  
  
  
  
  
  

 .MuiPopover-paper {
    background-color: rgb(13, 30, 70)
    !important;
    color: white  !important;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 16px;
    min-height: 16px;
    max-width: calc(100% - 32px);
    max-height: calc(100% - 32px);
    outline: 0;
}


.disabled-button {
  background-color: #b8c548;
  opacity: 0.5; 
  cursor: not-allowed; 
  pointer-events: none; 
}

